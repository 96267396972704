import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSciDomain } from 'components';
import { EventOptionPaymentStepName, OrderStatus } from 'enums';
import { useTrackPaymentStepDuration } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: OrderStatus.Requisites | OrderStatus.TraderConfirm;
};

export const PaymentLoading: React.FC<Props> = ({ status }) => {
  useTrackPaymentStepDuration(
    status === OrderStatus.Requisites
      ? EventOptionPaymentStepName.Requisites
      : EventOptionPaymentStepName.TraderConfirm,
  );

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.payment_loading',
  });

  const { data: { loader } = {} } = useSciDomain();

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-2xl tw-mx-auto tw-p-16 tw-rounded-2xl tw-bg-payment-layout-out md:tw-py-40">
      {loader ? (
        <img
          src={loader}
          alt="logo-loader"
          className="tw-w-48 tw-mb-8 tw-animate-pulse"
        />
      ) : (
        <span className="tw-text-secondary tw-text-center">{t('loading')}</span>
      )}
    </div>
  );
};
