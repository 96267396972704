import Uppy, { UploadedUppyFile, UploadResult } from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Webcam from '@uppy/webcam';
import { AxiosError } from 'axios';
import { forEach } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ordersApi } from 'api';
import { useSciDomain } from 'components';
import { QueryKey, SciThemeToUppyDialogMap } from 'enums';
import { useLanguage } from 'hooks';
import { Order } from 'types';
import { gaUtils } from 'utils';

import { MAP_LANGUAGE_TO_UPPY_LOCALE } from './constants';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

const uppy = new Uppy({
  autoProceed: false,
  allowMultipleUploads: false,
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/*', 'application/pdf'], // Only allow image files and pdf
    maxFileSize: 1024 * 1024 * 3, // 3MB max file size
  },
}).use(Webcam);

type Props = {
  open: boolean;
  order: Order;
  onUpload: () => void;
  onClose: () => void;
  localeOverride?: Record<string, string | object>;
};

export const UploadReceiptDialog: React.FC<Props> = ({
  order,
  open,
  onUpload,
  onClose,
  localeOverride,
}) => {
  const queryClient = useQueryClient();

  const { language } = useLanguage();

  useEffect(() => {
    const loadLocale = async () => {
      try {
        const localeCode = MAP_LANGUAGE_TO_UPPY_LOCALE[language];
        const locale = await import(`@uppy/locales/lib/${localeCode}`);
        uppy.setOptions({
          locale: {
            strings: {
              ...locale.default.strings,
              ...localeOverride,
            },
          },
        });
      } catch (e: any) {
        gaUtils.trackException({
          fatal: false,
          description: 'Language loading failed: ' + e.message,
        });
      }
    };
    loadLocale();
  }, [localeOverride, language]);

  const removeFiles = useCallback(() => {
    const files = uppy.getFiles();
    forEach(files, (file) => {
      uppy.removeFile(file.id);
    });
  }, []);

  const { mutate: uploadPaymentReceipt } = useMutation(
    ordersApi.uploadReceipt,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.OrderReceipts);
        removeFiles();
        onUpload();
        onClose();
      },
      onError: (e: AxiosError<{ message: string | undefined }>) => {
        uppy.info(e?.response?.data?.message || e?.message, 'error');
        uppy.resetProgress();
      },
    },
  );

  const handleUpload = useCallback(
    (file: UploadedUppyFile<any, any>) => {
      const formData = new FormData();
      formData.append('file', file.data, file.name);

      uploadPaymentReceipt({
        orderId: order.id,
        body: formData,
      });
    },
    [order.id, uploadPaymentReceipt],
  );

  const handleFileComplete = useCallback(
    (result: UploadResult<Record<string, unknown>>) => {
      const file = result.successful[0];
      handleUpload(file);
    },
    [handleUpload],
  );

  useEffect(() => {
    uppy.on('complete', handleFileComplete);
    return () => {
      uppy.off('complete', handleFileComplete);
    };
  }, [handleFileComplete]);

  const { data: { theme } = {} } = useSciDomain();
  const modalTheme = theme ? SciThemeToUppyDialogMap[theme] : 'dark';

  return (
    <DashboardModal
      open={open}
      uppy={uppy}
      plugins={['Webcam']}
      proudlyDisplayPoweredByUppy={false}
      onRequestClose={onClose}
      theme={modalTheme}
    />
  );
};
