import { Bank, FiatCurrencyCode, PaymentType } from 'enums';
import { TradeMethod } from 'types';

const DEFAULT_BANKS = [Bank.Sberbank, Bank.Tinkoff];

type BanksByTradeMethodMapItem = {
  isMatch: (tradeMethod: TradeMethod) => boolean;
  banks: Bank[];
};

export const BANKS_BY_TRADE_METHOD: BanksByTradeMethodMapItem[] = [
  {
    isMatch: ({ bank, paymentType }) =>
      paymentType === PaymentType.SBP &&
      (bank === Bank.Amra || bank === Bank.Amobile),
    banks: [Bank.Tinkoff],
  },
  {
    isMatch: ({ bank, paymentType }) =>
      paymentType === PaymentType.SBP && bank === Bank.VTB,
    banks: [Bank.VTB],
  },
  {
    isMatch: ({ paymentType, fiatCurrency }) =>
      paymentType === PaymentType.TSBP && fiatCurrency === FiatCurrencyCode.TJS,
    banks: [...DEFAULT_BANKS, Bank.VTB],
  },
  {
    isMatch: ({ paymentType, fiatCurrency }) =>
      paymentType === PaymentType.TCard2Card &&
      fiatCurrency === FiatCurrencyCode.KGS,
    banks: [...DEFAULT_BANKS, Bank.VTB],
  },
  {
    isMatch: ({ paymentType, fiatCurrency }) =>
      paymentType === PaymentType.TCard2Card &&
      fiatCurrency === FiatCurrencyCode.ABH,
    banks: [Bank.Tinkoff],
  },
  {
    isMatch: ({ paymentType }) => paymentType === PaymentType.TSBP,
    banks: DEFAULT_BANKS,
  },
];
