import i18next from 'i18next';
import { ReactNode } from 'react';

import { Bank, FiatCurrencyCode } from 'enums';

export type PaymentRuleOptions = {
  isTSBP?: boolean;
  isSBP?: boolean;
  isTCard2Card?: boolean;
  customerBank?: string;
  customerBankName?: string;
  requisitesCountry?: string;
  fiatCurrency?: string;
  requisitesBank?: string;
};

type PaymentRuleRenderFn = (options: PaymentRuleOptions) => ReactNode;
type PaymentRule = {
  isMatch: (options: PaymentRuleOptions) => boolean;
  render: PaymentRuleRenderFn;
};

const t = (key: string, options?: Record<string, string | undefined>) =>
  i18next.t(`components.payment_details.${key}`, {
    defaultValue: key,
    ...options,
  });

const DetailsList = ({
  rows = [],
  showOtherBanksRule,
  children,
}: {
  rows?: string[];
  showOtherBanksRule?: boolean;
  children?: React.ReactNode;
}) => (
  <ul>
    {rows.map((ruleText, index) => (
      <li
        key={index}
        className="tw-flex tw-items-center tw-mb-2 tw-text-primary last-of-type:tw-mb-0"
      >
        <div className="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-basis-9 tw-h-9 tw-mr-4 tw-font-bold tw-text-layout-accent tw-rounded-full tw-bg-layout-accent-alt">
          {(++index).toString().padStart(2, '0')}
        </div>
        {ruleText}
      </li>
    ))}
    {showOtherBanksRule && (
      <li className="tw-text-primary tw-font-bold tw-mt-4">
        {t('rules.other_banks')}
      </li>
    )}
    {children}
  </ul>
);

export const DEFAULT_PAYMENT_RULE_RENDER: PaymentRuleRenderFn = () => (
  <DetailsList
    rows={[t('rules.rule_one'), t('rules.rule_two'), t('rules.rule_three')]}
  />
);

export const PAYMENT_RULES: PaymentRule[] = [
  {
    isMatch: ({ isTSBP, requisitesBank }) =>
      !isTSBP && requisitesBank === Bank.Namba,
    render: () => (
      <DetailsList
        rows={[
          t('rules_tbank_kgs.rule_one'),
          t('rules_tbank_kgs.rule_two'),
          t('rules_tbank_kgs.rule_three'),
          t('rules_tbank_kgs.rule_four'),
          t('rules_tbank_kgs.rule_five'),
        ]}
      />
    ),
  },
  {
    isMatch: ({ isSBP, requisitesBank, customerBank }) =>
      (requisitesBank === Bank.Amra || requisitesBank === Bank.Amobile) &&
      !!isSBP &&
      customerBank === Bank.Tinkoff,
    render: ({ customerBankName }) => (
      <DetailsList
        rows={[
          t('rules_sbp_tinkoff_amra_amobile.rule_one', {
            bank: customerBankName,
          }),
          t('rules_sbp_tinkoff_amra_amobile.rule_two'),
          t('rules_sbp_tinkoff_amra_amobile.rule_three'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isSBP, requisitesBank, customerBank }) =>
      requisitesBank === Bank.VTB && customerBank === Bank.VTB && !!isSBP,
    render: () => (
      <DetailsList
        rows={[t('rules.rule_one'), t('rules.rule_two'), t('rules.rule_three')]}
      >
        <li className="tw-text-center tw-text-red-500 tw-font-bold tw-mt-4 tw-p-3 tw-border-2 tw-rounded-xl tw-border-red-500">
          {t('rules_spb_vtb_vtb.rule_last')}
        </li>
      </DetailsList>
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank, fiatCurrency }) =>
      !!isTSBP &&
      customerBank === Bank.VTB &&
      fiatCurrency === FiatCurrencyCode.TJS,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_vtb_tjs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_vtb_tjs.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tsbp_vtb_tjs.rule_three'),
          t('rules_tsbp_vtb_tjs.rule_four'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank, fiatCurrency }) =>
      !!isTSBP &&
      customerBank === Bank.Tinkoff &&
      fiatCurrency === FiatCurrencyCode.KGS,
    render: ({ customerBankName }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_tinkoff_kgs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_tinkoff_kgs.rule_two'),
          t('rules_tsbp_tinkoff_kgs.rule_three'),
          t('rules_tsbp_tinkoff_kgs.rule_four'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank, fiatCurrency }) =>
      !!isTSBP &&
      customerBank === Bank.Tinkoff &&
      fiatCurrency === FiatCurrencyCode.TJS,
    render: ({ customerBankName }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_tinkoff_tjs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_tinkoff_tjs.rule_two'),
          t('rules_tsbp_tinkoff_tjs.rule_three'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank }) =>
      !!isTSBP && customerBank === Bank.Tinkoff,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_tinkoff.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_tinkoff.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tsbp_tinkoff.rule_three'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank, fiatCurrency }) =>
      !!isTSBP &&
      customerBank === Bank.Sberbank &&
      fiatCurrency === FiatCurrencyCode.AZN,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_sberbank_azn.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_sberbank_azn.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tsbp_sberbank_azn.rule_three'),
          t('rules_tsbp_sberbank_azn.rule_four'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank, fiatCurrency }) =>
      !!isTSBP &&
      customerBank === Bank.Sberbank &&
      fiatCurrency === FiatCurrencyCode.TJS,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_sberbank_tjs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_sberbank_tjs.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tsbp_sberbank_tjs.rule_three'),
          t('rules_tsbp_sberbank_tjs.rule_four'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTSBP, customerBank }) =>
      !!isTSBP && customerBank === Bank.Sberbank,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tsbp_sberbank.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tsbp_sberbank.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tsbp_sberbank.rule_three'),
          t('rules_tsbp.rule_last'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTCard2Card, fiatCurrency, customerBank }) =>
      !!isTCard2Card &&
      fiatCurrency === FiatCurrencyCode.KGS &&
      customerBank === Bank.Sberbank,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tcard2card_sberbank_kgs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tcard2card_sberbank_kgs.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tcard2card_sberbank_kgs.rule_three'),
          t('rules_tcard2card_sberbank_kgs.rule_four'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTCard2Card, fiatCurrency, customerBank }) =>
      !!isTCard2Card &&
      fiatCurrency === FiatCurrencyCode.KGS &&
      customerBank === Bank.VTB,
    render: ({ customerBankName, requisitesCountry }) => (
      <DetailsList
        rows={[
          t('rules_tcard2card_vtb_kgs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tcard2card_vtb_kgs.rule_two', {
            country: requisitesCountry,
          }),
          t('rules_tcard2card_vtb_kgs.rule_three'),
          t('rules_tcard2card_vtb_kgs.rule_four'),
          t('rules_tcard2card_vtb_kgs.rule_five'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTCard2Card, fiatCurrency, customerBank }) =>
      !!isTCard2Card &&
      fiatCurrency === FiatCurrencyCode.KGS &&
      customerBank === Bank.Tinkoff,
    render: ({ customerBankName }) => (
      <DetailsList
        rows={[
          t('rules_tcard2card_tinkoff_kgs.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tcard2card_tinkoff_kgs.rule_two'),
          t('rules_tcard2card_tinkoff_kgs.rule_three'),
        ]}
        showOtherBanksRule
      />
    ),
  },
  {
    isMatch: ({ isTCard2Card, fiatCurrency, customerBank }) =>
      !!isTCard2Card &&
      fiatCurrency === FiatCurrencyCode.ABH &&
      customerBank === Bank.Tinkoff,
    render: ({ customerBankName }) => (
      <DetailsList
        rows={[
          t('rules_tcard2card_tinkoff_abh.rule_one', {
            bank: customerBankName,
          }),
          t('rules_tcard2card_tinkoff_abh.rule_two'),
          t('rules_tcard2card_tinkoff_abh.rule_three'),
        ]}
        showOtherBanksRule
      />
    ),
  },
];
